<template>
  <div id="servisWrapper">
    <div id="tableWrapper">
      <table id="isporuka">
        <thead>
          <tr>
            <th>RED VOŽNJE</th>
            <th>PON</th>
            <th>UTO</th>
            <th>SRI</th>
            <th>ČET</th>
            <th>PET</th>
            <th>SUB</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Bioče</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Bar<br />Sutomore</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Berane</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
          </tr>
          <tr>
            <td>Bijelo Polje</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
          </tr>
          <tr>
            <td>Budva</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
          </tr>
          <tr>
            <td>Cetinje</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Danilovgrad <br /> Spuž</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
          </tr>
          <tr>
            <td>Herceg Novi</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Kolašin</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Kotor</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
          </tr>
          <tr>
            <td>Mojkovac</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Nikšić</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" /></td>
          </tr>
          <tr>
            <td>Petrovac</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Šavnik</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Pljevlja</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Podgorica</td>
            <td><img src="/img/avto.png" /></td>
            <td><img src="/img/avto.png" /></td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td><img src="/img/avto.png" /></td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td><img src="/img/avto.png" /></td>
          </tr>
          <tr>
            <td>Bioče</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Tivat</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
          </tr>
          <tr>
            <td>Tuzi</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Ulcinj</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Žabljak</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td>Zeta</td>
            <td><img src="/img/avto.png" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
            <td><img src="/img/avto.png" alt="" /></td>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </table>
      <br />
      
      
        Rijeka Crnojevića, Njeguši - utorak/jednom u 15 dana<br />
		Kuči - petak/jednom u 15 dana<br />
		Virpazar, Crmnica - ponedjeljak/jednom u 15 dana<br />
		Piperi - subota/jednom u 15 dana<br />
		Plužine - subota/jednom u 15 dana<br />
		Grahovo - subota/jednom u 15 dana<br />
		Rožaje, Petnjica - srijeda/jednom u 15 dana<br />
		Sela u okolini Rožaja (Radetina, Bisevo, Bać) - srijeda/jednom u 15 dana<br />
		Andrijevica, Plav, Gusinje - srijeda/jednom u 15 dana<br />
		Boan - petak/jednom mjesečno<br />
		Veruša, Mateševo - petak/jednom u 15 dana<br />
		Sela u okolini Pljevalja - petak/jednom mjesečno<br />
		Pavino Polje, Kovren, Tomaševo, Mataruge, Vrulja - petak/jednom mjesečno<br />
		Boljanici, Potkovac, Metaljka - petak/jednom mjesečno<br />

		<br /><br />
		
		
		NAPOMENA - Rozaje, Petnjicu, Plav, Gusinje, Andrijevicu vozimo petnaestodnevno<br />
		NAPOMENA - Ulcinj vozimo Ponedeljkom, Srijedom i Petkom.<br />
		NAPOMENA - Petrovac, Buljarica, Rijeka Rezevici vozimo samo Srijedom.<br />
		NAPOMENA - pri kupovini vece kolicine robe javiti se Darku Đ. za dodatni dogovor ( za Petrovac, Buljaricu, Rozaje, Plav, Gusinje, Andrijevicu ).<br />
		NAPOMENA - BIOCE vozimo jednom sedmicno - SRIJEDOM<br />
		NAPOMENA - ZA SELA van gradova i manje iznose racuna kupci preuzimaju robu u najblizem gradu, a za vece iznose racuna ako je kupcima hitno po dogovoru da kontaktiraju dispecera Darka Đukića i Željka Tatara radi dogovora varedne isporuke.<br />
		
		<br /><br />

		
		01.02.2025 vozimo Grahovo, Vilusi, Vraćenovici <br />
        03.02.2025 vozimo Virpazar, Crmnica, Ostros <br />
        05.02.2025 vozimo Plav, Gusinje, Andrijevicu <br />
        07.02.2025 vozimo Pljevlja - selo Šula i ostala sela u tom pravcu <br />
        08.02.2025 vozimo Plužine, Piperi, Studeno <br />
        11.02.2025 vozimo Rijeku Crnojevica, NjeguŠi <br />
        12.02.2025 vozimo Rožaje ( selo Bac ), Petnjicu <br />
        14.02.2025 vozimo Pljevlja - sela Pavino Polje, Kovren, Tomaševo, Mataruge, Vrulja <br />
        14.02.2025 vozimo Verušu, Mateševo nalozi se vade sa Kolašinom <br />
        14.02.2025 vozimo Kuče nalozi se vade sa gradom <br />
        15.02.2025 vozimo Grahovo, Vilusi, Vraćenovići <br />
        17.02.2025 vozimo Virpazar, Crmnica, Ostros <br />
        19.02.2025 vozimo Plav, Gusinje, Andrijevicu <br />
        21.02.2025 vozimo Pljevlja - sela Boljaniće, Potkovac, Metaljka <br />
        22.02.2025 vozimo Plužine, Piperi, Studeno  <br />
        25.02.2025 vozimo Rijeku Crnojevica, Njeguši <br />
        26.02.2025 vozimo Rožaje ( sela - Radeta, Bisevo ), Petnjicu <br />
        28.02.2025 vozimo Šavnik, Boan i ostala sela u tom pravcu <br />
        28.02.2025 vozimo Verušu, Mateševo nalozi se vade sa Kolašinom <br />
        28.02.2025 vozimo Kuče nalozi se vade sa gradom <br /><br />
      
      
    </div>

    <div id="pravilnikWrapper">
      <h2>Informacije</h2>
      
      <p>
      1. Informacije o vremenu montaže robe i prijava reklamacija pločastog namještaja 067/666-300 od 08h-18h prijava reklamacija tapaciranog namještaja 067/666-961 od 08h-18h <br />
2. Informacije o vremenu isporuke, prijava reklamacija 067/666-400 od 08h-18h <br />
3. Informacije o uslovima prodaje namještaja 067/666-150 <br />
4. Informacije o prispjeću poručene robe 067/666-915 <br />
5. Pohvale i primjedbe na rad osoblja magacina, montaže, prevoza. 063/222-454 od 08h-18h mail: goran.lasica@namos.co.me <br />
6. Računovodstvo 063/222-190 od 08h-16h <br /><br />
</p>
      
      
      
      
      <h2>Pravilnik kompanije</h2>
      <p>
      
     1. Prodavac preuzima obavezu besplatnog prevoza  za sve gradove Crne Gori  i montaže robe (ne uključujući nedelju , državne i vjerske praznike), koja se vrši najkasnije u roku – do 72h, od momenta prijema robe.<br />
2. Prodavac NE VRŠI UNOŠENJE ROBE u stan kupca I NE KAČI ELEMENTE NA ZID. Za neuklapanje artikala u stambeni prostor potrošača, prodavac ne snosi odgovornost.<br />
3. Isporučena, otpakovana ili namontirana roba, kao i roba prodata (proknjižena) u prethodnom mjesecu ne može se zamijeniti.<br />
4. Prodavac se obavezuje da prema uslovima iz ovog ugovora poruči i proda robu kupcu, u svemu po specifikaciji koja je sastavni dio ugovora.<br />
5. Za poručenu robu kupac se obavezuje da uplati avans, minimum 50% od ukupne vrijednosti naručene robe, nakon čega počinju teći rokovi iz člana 6. Preostalih 50% plaća se po prispjeću robe u centralni magacin.<br />
6. Rok za prispjeće robe u centralni magacin prodavca naglašen je na računu, s tim, što se u slučaju nepredviđenih okolnosti: smetnji u prevozu, vremenskih nepogoda, zadržavanju na carinskom terminalu, ovaj rok može produžiti za još 7 dana.<br />
7. U slučaju prekoračenja rokova isporuke, iz člana 6. ugovora, kupac zadržava pravo na povraćaj avansa sa zakonskom zateznom kamatom, ili dodatnim popustom 10%, ili se nudi zamjena za drugi artikal, uz odgovarajući popust od strane "Dajković Co Namos"<br />
8. Roba koja se uplaćuje virmanski iskucava se po zvaničnim izvodima i isporuka se vrši po planu prevoza, nakon izdate fakture.<br />
9. Za neblagovremeno plaćanje, obračunavamo zateznu kamatu. Molimo da se pri plaćanju pozovete na broj računa.<br />
U slučaju spora nadležan je Privredni sud u Podgorici.
<br /><br />

      
      
      
      
        
        <strong>NAPOMENA:</strong>
        
        Poštovani kupci, zbog iskrene i velike želje da budete ispoštovani, firma “Dajković Co Namos’’ je obezbijedila video nadzor, kako salona tako i svih vozila, robe u transportu kao i samu primopredaju robe. Svi zaposleni sa kojima komunicirate imaju ID kartice. U slučaju bilo kakve sporne situacije, molimo Vas da kontaktirate naše službe. Ako niste zadovoljni odgovorom, na raspolaganju Vam je e-mail adresa generalnog direktora i vlasnika: dajkovic.company@namos.co.me.
        
        <br /><br />
        
        
       
        
        <strong>Vaš svijet namještaja "Dajković Co Namos"</strong>
        
        <br /><br />
        <h2>Ovlašćeni servisni centri po regijama za montažu - INFO KONTAKT:</h2>
        
        <p>
        
        • Herceg Novi, Kotor, Tivat........................................................063/222-059<br />
		• Budva...................................................................................... 069/922-204<br />
		• Nikšić, Grahovo, Plužine..........................................................067/666-952<br />
		• Pljevlja, Žabljak, Šavnik........................................................... 063/222-874<br />
		• Kolašin, Mojkovac...................................................................  069/598-272<br />
		• Berane, Andrijevica, Rožaje, Plav, Gusinje............................. 068/350-636<br />
		• Bijelo Polje............................................................................... 068/087-515<br />
		• Bar, Sutomore, Ulcinj, Petrovac...............................................069/077-807<br />
		•  Danilovgrad............................................................................. 069/232-506<br />

        </p>
        
        
    </div>
    
    <div id="faqWrapper">
      <div>
        <h2><a name="cesta_pitanja"></a>FAQ - Česta pitanja</h2>
        <p>
          <b> - Pitanje:</b><br />
          Da li namještaj može da se plati po isporuci?<br />
          <b> - Odgovor:</b><br />
          Namještaj nije moguće platiti po isporuci. Po pravilniku firme mora
          biti uplaćen kompletan iznos sa predračuna da bi se obavila isporuka.
          <br /><br />

          <b> - Pitanje:</b><br />
          Da li Namos ima prodajnih mjesta u nekom drugom gradu mimo
          Podgorice?<br />
          <b> - Odgovor:</b><br />
          Nema, naš specijalizovani salon namještaja na 4200m2 se nalazi u
          Podgorici (Cetinjski put bb - Donja Gorica). Ukoliko niste u
          mogućnosti da nas posjetite, moguće je telefonom poručiti namještaj
          koji možete pogledati na sajtu ili u flajerima i katalozima koji često
          izlaze uz dnevne novine Vijesti i Dan. Naravno prevoz i montaža su
          besplatni.<br /><br />
          
          
        </p>
      </div>
      
      
      
      
      
      
      
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss">



// MOBILE =================================================================================================
@media only screen and (max-width: 600px) {

#servisWrapper {
  position: relative;
  width: 100vw;
  padding: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}

#faqWrapper {
  width: 100%;
}

#tableWrapper {
  width: 100%;
}

#isporuka {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 3vw;
  position: relative;
}

#isporuka tr:first-child {
  width: 10vw;
}

#isporuka td,
#isporuka th {
  border: 1px solid #ddd;
  padding:2vw;
}

#isporuka tr:nth-child(even) {
  background-color: #f2f2f2;
}

#isporuka tr:hover {
  background-color: #ddd;
}

#isporuka th {
  padding-top: 2vw;
  padding-bottom: 2vw;
  text-align: center;
  background-color: #62a846;
  color: white;
  position: sticky;
  top: 0px;
}
#isporuka td {
  padding-top: 1vw;
  padding-bottom: 1vw;
  text-align: left;
}

#isporuka img {
  height: 4vw;
}

#pravilnikWrapper {
  width: 100%;
}
}







// DESKTOP =================================================================================================

@media only screen and (min-width: 601px) {

#servisWrapper {
  position: relative;
  width: 100vw;
  padding: 3vw;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

#faqWrapper {
  width: 20vw;
}

#tableWrapper {
  width: 30vw;
}

#isporuka {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 1vw;
  position: relative;
}

#isporuka tr:first-child {
  width: 10vw;
}

#isporuka td,
#isporuka th {
  border: 1px solid #ddd;
  padding: 8px;
}

#isporuka tr:nth-child(even) {
  background-color: #f2f2f2;
}

#isporuka tr:hover {
  background-color: #ddd;
}

#isporuka th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #62a846;
  color: white;
  position: sticky;
  top: 0px;
}
#isporuka td {
  padding-top: 0.4vw;
  padding-bottom: 0.4vw;
  text-align: left;
}

#isporuka img {
  height: 1vw;
}

#pravilnikWrapper {
  width: 34vw;
}
}
</style>
